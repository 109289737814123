import { UploadFile } from 'antd/lib/upload/interface'
import moment from 'moment'

import {
    BeneficialOwner,
    DocumentType,
    FieldNamesOnlyFile,
    HasBeneficiary,
    IIndividualProfile,
    IndividualFormValues,
    OtherDocType,
    UserAccountProfileDetails,
} from '../../models'
import { getDateWithoutTime, getDateWithoutTimeString } from '../../utils'

import { getInitialFile } from '../common'

import { beneficiariesFrontToBack, beneficiaryBackToFront } from './beneficiary'
import {
    getBankAccountDetailsFromBackend,
    getBankAccountDetailsFromForm,
    getBankDetailsProfileFromForm,
    getCommonDataFromBackend,
    getCommonDataFromForm,
    getInitialFileFromDocs,
    getPublicOfficialFromBackend,
    getPublicOfficialFromForm,
} from './common'

const otherDocFieldNames: FieldNamesOnlyFile[] = ['inn_file', 'snils_file']

const getDocObject = (fieldName: FieldNamesOnlyFile, formValues: IndividualFormValues) => ({
    title: fieldName,
    file_uuids: (formValues[fieldName] as UploadFile[]).map(({ uid }) => uid),
})

export const individualFrontToBack = (valuesFromForm: IndividualFormValues): IIndividualProfile => {
    const docs: IIndividualProfile['docs'] = []
    otherDocFieldNames.forEach((fieldName) => {
        if (valuesFromForm[fieldName]) {
            docs.push(getDocObject(fieldName, valuesFromForm))
        }
    })

    const passportData =
        valuesFromForm.documentType === DocumentType.passport
            ? {
                  document_type: valuesFromForm.documentType,
                  passport: {
                      series: valuesFromForm.PassportSeries,
                      number: valuesFromForm.PassportNumber,
                      issue_date: valuesFromForm.issueDate.unix(),
                      issued_by: valuesFromForm.PassportIssuer,
                      issuer_department_code: valuesFromForm.PassportCode,
                      file_uuid: valuesFromForm.PassportPages?.map(({ uid }) => uid).join(),
                  },
              }
            : { document_type: valuesFromForm.documentType }

    return {
        person: {
            first_name: valuesFromForm.first_name,
            last_name: valuesFromForm.last_name,
            second_name: valuesFromForm.second_name,
            birthdate: getDateWithoutTime(valuesFromForm.birthdate) as unknown as number,
            place_birth: valuesFromForm.place_birth,
            citizenship: valuesFromForm.citizenship,
            registration_address: valuesFromForm['main_address.registration'],
            location_address:
                valuesFromForm['main_address.fact'] || valuesFromForm['main_address.registration'],
            post_address: valuesFromForm['main_address.index'],
            inn: valuesFromForm.inn,
            snils: valuesFromForm.snils,
            ...passportData,
        },
        public_official: getPublicOfficialFromForm(valuesFromForm),
        bank_details: getBankDetailsProfileFromForm(valuesFromForm),
        bank_account_details: getBankAccountDetailsFromForm(
            valuesFromForm,
            `${valuesFromForm.last_name} ${valuesFromForm.first_name}${
                valuesFromForm.second_name ? ` ${valuesFromForm.second_name}` : ''
            }`,
        ),
        ...getCommonDataFromForm(valuesFromForm),
        docs: docs.length ? docs : undefined,
    }
}

export const individualBackToFront = (
    anketaFromBackend: IIndividualProfile,
    beneficialOwners?: BeneficialOwner[],
): IndividualFormValues => {
    const passportData =
        anketaFromBackend.person?.document_type === DocumentType.passport
            ? {
                  documentType: anketaFromBackend.person.document_type,
                  PassportSeries: anketaFromBackend.person.passport.series,
                  PassportNumber: anketaFromBackend.person.passport.number,
                  issueDate: moment.unix(anketaFromBackend.person.passport.issue_date),
                  PassportIssuer: anketaFromBackend.person.passport?.issued_by,
                  PassportCode: anketaFromBackend.person.passport?.issuer_department_code,
                  PassportPages: getInitialFile(anketaFromBackend.person.passport?.file_uuid),
              }
            : {
                  documentType: anketaFromBackend.person?.document_type,
              }
    const otherDocs = (otherDocFieldNames as Array<keyof OtherDocType>).reduce(
        (docsFields, fieldName) => {
            docsFields[fieldName] = getInitialFileFromDocs(fieldName, anketaFromBackend.docs)
            return docsFields
        },
        {} as OtherDocType,
    )

    return {
        id: anketaFromBackend.id,
        last_name: anketaFromBackend.person.last_name,
        first_name: anketaFromBackend.person.first_name,
        second_name: anketaFromBackend.person.second_name ?? '',
        birthdate: getDateWithoutTimeString(anketaFromBackend.person.birthdate),
        place_birth: anketaFromBackend.person.place_birth,
        citizenship: anketaFromBackend.person.citizenship,
        'main_address.registration': anketaFromBackend.person.registration_address,
        'main_address.fact': anketaFromBackend.person.location_address,
        'main_address.index': anketaFromBackend.person.post_address,
        inn: anketaFromBackend.person.inn,
        snils: anketaFromBackend.person.snils,
        ...passportData,
        ...getPublicOfficialFromBackend(anketaFromBackend.public_official),
        ...getBankAccountDetailsFromBackend(
            anketaFromBackend.bank_account_details ??
                anketaFromBackend?.user_bank_account_details?.bank_account_details,
        ),
        ...getCommonDataFromBackend(anketaFromBackend),
        beneficiaries: beneficialOwners?.map(beneficiaryBackToFront),
        hasBeneficiary: anketaFromBackend.beneficiaries?.length
            ? HasBeneficiary.YES
            : HasBeneficiary.NO,
        ...otherDocs,
    }
}

export const individualForUpdateData = (
    valuesFromForm: IndividualFormValues,
    oldProfileFromBackend: IIndividualProfile,
) => {
    const newProfile = individualFrontToBack(valuesFromForm)
    const account_details = {
        ...oldProfileFromBackend.user_bank_account_details?.bank_account_details,
        ...newProfile.bank_account_details,
    }
    return {
        individual: {
            ...oldProfileFromBackend,
            ...newProfile,
            node_and_exchange_operator: undefined,
        },
        linked_beneficiaries:
            valuesFromForm.beneficiaries && valuesFromForm.hasBeneficiary === HasBeneficiary.YES
                ? beneficiariesFrontToBack(valuesFromForm.beneficiaries)
                : undefined,
        user_account_details: [
            {
                ...oldProfileFromBackend.user_bank_account_details,
                bank_account_details: undefined,
                account_details,
            } as UserAccountProfileDetails,
        ],
    }
}
