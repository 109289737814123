import moment from 'moment'

import {
    AttachmentModel,
    BankAccountDetailsModel,
    BankDetailsModel,
    IBasicFinanceInformation,
    IFinanceAndOperation,
    LicActivityModel,
    MigrationCardModel,
    PermissionStayRFModel,
    PublicOfficialModel,
    PublicStatusType,
    PurposesOfActivityType,
    UIBankAccountDetailsType,
    UIFinanceInfo,
    UILicActivityType,
    UIMigrationCardType,
    UIPermissionType,
    UIPlannedOperations,
    UIPublicPersonForm,
} from '../../models'
import { getInitialFile, getInitialFileFromDoc, getInitialFiles } from '../common'
import { ProfileType } from '../../..'

export const getInitialFileFromDocs = (fieldName: string, docs?: AttachmentModel[] | null) => {
    const foundDoc = docs?.find((doc) => doc.title === fieldName)
    return foundDoc ? foundDoc.file_uuids.map(getInitialFileFromDoc) : undefined
}
export const getInitialList = <T extends string>(
    fieldName: T,
    list?: string[],
): Record<T, string>[] =>
    list?.length
        ? (list
              .filter((value) => Boolean(value))
              .map((value) => ({ [fieldName]: value })) as Record<T, string>[])
        : [{} as Record<T, string>]

type MigrationDocsBackendType = {
    permission_stay_rf?: PermissionStayRFModel
    migration_card?: MigrationCardModel
}
export const getMigrationDocFromBackend = (valuesFromBackend: MigrationDocsBackendType) => {
    const migrationData = valuesFromBackend.migration_card?.number
        ? {
              HasMigrationCard: true,
              MigrationCardArrivalDate: moment.unix(valuesFromBackend.migration_card.start_date),
              MigrationCardDepartureDate: moment.unix(
                  valuesFromBackend.migration_card.expiration_date,
              ),
              MigrationCardDocuments: getInitialFile(valuesFromBackend.migration_card.file_uuid),
              MigrationCardNumber: valuesFromBackend.migration_card.number,
          }
        : {
              HasMigrationCard: false,
          }
    const permissionData = valuesFromBackend.permission_stay_rf?.number
        ? {
              HasMigrationPermit: true,
              MigrationPermitArrivalDate: moment.unix(
                  valuesFromBackend.permission_stay_rf.start_date,
              ),
              MigrationPermitDepartureDate: moment.unix(
                  valuesFromBackend.permission_stay_rf.expiration_date,
              ),
              MigrationPermitDocuments: getInitialFile(
                  valuesFromBackend.permission_stay_rf.file_uuid,
              ),
              MigrationPermitName: valuesFromBackend.permission_stay_rf.name,
              MigrationPermitNumber: valuesFromBackend.permission_stay_rf.number,
              MigrationPermitSeries: valuesFromBackend.permission_stay_rf.series,
          }
        : {
              HasMigrationPermit: false,
          }

    return { ...migrationData, ...permissionData }
}

type MigrationDocsFrontendType = UIMigrationCardType & UIPermissionType

export const getMigrationDocFromForm = (valuesFromForm: MigrationDocsFrontendType) => {
    const migration_card = valuesFromForm.HasMigrationCard
        ? {
              number: valuesFromForm.MigrationCardNumber as string,
              start_date: valuesFromForm.MigrationCardArrivalDate?.unix() as number,
              expiration_date: valuesFromForm.MigrationCardDepartureDate?.unix() as number,
              file_uuid: valuesFromForm.MigrationCardDocuments?.[0]?.uid as string,
          }
        : undefined
    const permission_stay_rf = valuesFromForm.HasMigrationPermit
        ? {
              name: valuesFromForm.MigrationPermitName as string,
              series: valuesFromForm.MigrationPermitSeries as string,
              number: valuesFromForm.MigrationPermitNumber as string,
              start_date: valuesFromForm.MigrationPermitArrivalDate?.unix() as number,
              expiration_date: valuesFromForm.MigrationPermitDepartureDate?.unix() as number,
              file_uuid: valuesFromForm.MigrationPermitDocuments?.[0]?.uid as string,
          }
        : undefined

    return { migration_card, permission_stay_rf }
}

export const getLicenseFromForm = <T extends UILicActivityType>(
    dataArray?: T[],
): LicActivityModel[] | undefined => {
    if (!dataArray?.length) {
        return undefined
    }
    return dataArray.map((data) => ({
        type_activity: data.type_activity,
        lic_number: data.lic_number,
        date_of_issue: data.date_of_issue?.unix(),
        issue_by: data.issue_by,
        validity: data.validity?.unix(),
        file_uuid: data.file_uuid?.map(({ uid }) => uid).join(),
    }))
}
export const getBankAccountDetailsFromForm = <T extends UIBankAccountDetailsType>(
    data: T,
    recipient: string,
): BankAccountDetailsModel => ({
    account_number: data.account_number,
    recipient,
    bank: data.bank,
    bic: data.bank_bic,
    bank_inn: data.bank_inn,
    bank_kpp: data.bank_kpp,
    corr_account_number: data.correspondent_account,
})
export const getBankAccountDetailsFromBackend = <T extends BankAccountDetailsModel>(
    data?: T,
): UIBankAccountDetailsType => {
    if (!data) {
        return {} as UIBankAccountDetailsType
    }
    return {
        account_number: data.account_number,
        bank: data.bank,
        bank_inn: data.bank_inn,
        bank_kpp: data.bank_kpp,
        bank_bic: data.bic,
        correspondent_account: data.corr_account_number,
    }
}

export const getBankDetailsProfileFromForm = <T extends UIBankAccountDetailsType>(
    data: T,
): BankDetailsModel => ({
    account: data.account_number,
    bank_title: data.bank,
    inn: data.bank_inn,
    kpp: data.bank_kpp,
    bik: data.bank_bic,
    corr_account: data.correspondent_account,
})

export const getStatusFromData = <T extends PublicOfficialModel>(data: T) => {
    if (data.am_i) {
        if (data.is_relative) {
            return PublicStatusType.related
        }
        return PublicStatusType.public
    }
    return PublicStatusType.notPublic
}

const getInitialRadio = (value?: boolean) => {
    if (value === true) {
        return 'Верно'
    }
    return 'Не верно'
}

export const getBasicInformationFromBackend = <T extends IBasicFinanceInformation>(
    data: T,
): UIFinanceInfo => ({
    isBankrotInProgress: getInitialRadio(data.bankruptcy_proceedings),
    isBankrot: getInitialRadio(data.bankruptcy_decisions),
    isLiquidationInProgress: getInitialRadio(data.liquidation_procedure),
    isDebtGov: getInitialRadio(data.facts_of_non_fulfillment),
    isNoMoney: getInitialRadio(data.debt_to_pay),
    finance_doc: getInitialFiles(data.file_uuids),
})

export const getBasicInformationFromForm = <T extends UIFinanceInfo>(
    data: T,
): IBasicFinanceInformation => ({
    bankruptcy_proceedings: data.isBankrotInProgress === 'Верно',
    bankruptcy_decisions: data.isBankrot === 'Верно',
    liquidation_procedure: data.isLiquidationInProgress === 'Верно',
    facts_of_non_fulfillment: data.isDebtGov === 'Верно',
    debt_to_pay: data.isNoMoney === 'Верно',
    file_uuids: data.finance_doc?.map(({ uid }) => uid),
})

export const getLicensesFromBackend = <T extends LicActivityModel>(
    dataArray?: T[],
): UILicActivityType[] | undefined => {
    if (!dataArray) {
        return undefined
    }
    return dataArray.map((data) => ({
        type_activity: data.type_activity,
        lic_number: data.lic_number,
        date_of_issue: moment.unix(data.date_of_issue),
        issue_by: data.issue_by,
        validity: data.validity ? moment.unix(data.validity) : undefined,
        file_uuid: getInitialFile(data.file_uuid),
    }))
}
export const getPublicOfficialFromForm = <T extends UIPublicPersonForm>(
    data: T,
): PublicOfficialModel => {
    const isPublicPerson = data.status !== PublicStatusType.notPublic
    return isPublicPerson
        ? {
              am_i: isPublicPerson,
              is_relative: data.status === PublicStatusType.related,
              relation: data.related_position as string,
              job: {
                  country: data.public_country as string,
                  title: data.public_organisation as string,
                  official_address: data.public_registration as string,
                  position_title: data.public_position as string,
              },
          }
        : { am_i: isPublicPerson }
}

export const getPublicOfficialFromBackend = (data: PublicOfficialModel): UIPublicPersonForm => {
    const publicCountry = data.job?.country ? { public_country: data.job?.country } : {}
    return {
        ...publicCountry,
        related_position: data.relation,
        public_organisation: data.job?.title,
        public_registration: data.job?.official_address,
        public_position: data.job?.position_title,
        status: getStatusFromData(data),
    }
}

export const getCommonDataFromBackend = <T extends IFinanceAndOperation>(
    data: T,
): UIPlannedOperations => {
    return {
        planned_operations: data.planned_operations,
        sources_funds: data.sources_funds,
        sources_funds_file_uuids: getInitialFiles(data.sources_funds_file_uuids),
        business_relations_type: data.business_relations_type,
        financial_activity_purpose: data.financial_activity_purpose || [],
    }
}
export const getCommonDataFromForm = <T extends UIPlannedOperations>(
    data: T,
): IFinanceAndOperation => {
    return {
        planned_operations: data.planned_operations,
        sources_funds: data.sources_funds,
        sources_funds_file_uuids: data.sources_funds_file_uuids?.map(({ uid }) => uid),
        business_relations_type: data.business_relations_type,
        financial_activity_purpose: data.financial_activity_purpose,
    }
}

export type FinancialOperationsStepProfileType = ProfileType.PRSN | ProfileType.LEGL | ProfileType.BUSN
